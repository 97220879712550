"use client";
import {
  ViewECommerceItemEvent,
  ViewECommerceItemListEvent,
  ViewPromotionEvent,
} from "@/tracking/ga-properties";
import tracker from "@/tracking/tracker";
import { isHTMLElement } from "@/tracking/utils";
import React, { useEffect } from "react";

type ComponentViewTrackerEvents =
  | ViewECommerceItemEvent
  | ViewPromotionEvent
  | ViewECommerceItemListEvent;

const useTrackerIntersectionObserver = (
  query: string,
  event: ComponentViewTrackerEvents,
) =>
  useEffect(() => {
    const observers: IntersectionObserver[] = [];
    document.querySelectorAll(query).forEach((item) => {
      const observer = new IntersectionObserver(
        (entry) => {
          if (
            entry.some(({ isIntersecting }) => isIntersecting) &&
            isHTMLElement(item)
          )
            tracker.trackEvent(event);
        },
        {
          threshold: 0.9,
        },
      );
      observer.observe(item);
      observers.push(observer);
    });
  });

const ComponentViewTracker = ({
  query,
  event,
}: {
  query: string;
  event: ComponentViewTrackerEvents;
}) => {
  useTrackerIntersectionObserver(query, event);
  return <></>;
};

export default ComponentViewTracker;
