import React, { useState, useRef, useEffect } from "react";
import { PortableTextBlock } from "@sanity/types";
import { PortableText } from "@/portable-text/PortableText";
import { ArbitraryTypedObject } from "@portabletext/types";

interface ExpandableTextProps {
  text: PortableTextBlock | ArbitraryTypedObject[];
  maxLines?: number;
  extraOffset?: number;
}

export const ExpandableText: React.FC<ExpandableTextProps> = ({
  text,
  maxLines = 5,
  extraOffset = 130,
}) => {
  const [isShowingMore, setIsShowingMore] = useState(false);
  const [isShowMoreButtonNeeded, setIsShowMoreButtonNeeded] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const element = textRef.current;
    if (element) {
      const isOverflowing = element.scrollHeight > element.clientHeight;
      setIsShowMoreButtonNeeded(isOverflowing);
    }
  }, []);

  const handleToggle = () => {
    if (isShowingMore) {
      const elementPosition =
        containerRef.current?.getBoundingClientRect().top || 0;
      const offsetPosition = window.scrollY + elementPosition;

      window.scrollTo({
        top: offsetPosition - extraOffset,
        behavior: "smooth",
      });
    }
    setIsShowingMore(!isShowingMore);
  };

  return (
    <div ref={containerRef}>
      <div
        ref={textRef}
        style={{
          display: "-webkit-box",
          WebkitLineClamp: isShowingMore ? "unset" : maxLines,
          WebkitBoxOrient: "vertical",
          overflow: isShowingMore ? "visible" : "hidden",
        }}
      >
        <PortableText value={text} />
        {!isShowingMore && isShowMoreButtonNeeded && <span>...</span>}
      </div>
      {isShowMoreButtonNeeded && (
        <button onClick={handleToggle} className="mt-2 font-medium underline">
          {isShowingMore ? "Les mindre" : "Les mer"}
        </button>
      )}
    </div>
  );
};
