import { Anchor } from "@/navigation";
import { getPageLink } from "@/navigation/selectors";
import { formatPrice } from "@/products/selectors";
import { getCSSPropertiesTheme } from "@/theming/getCSSProperties";
import { getThemeClassName } from "@/theming/selectors";
import { Theme } from "@/theming/types";
import { Button } from "@cappelendamm/design-system";
import { SubscriptionProduct } from "@cappelendamm/e-commerce-services";
import classNames from "classnames";
import { FC } from "react";
import { PiArrowRight } from "react-icons/pi";

export const SubscriptionHeaderContent: FC<{
  subscription: SubscriptionProduct;
  universeTag: string;
  buttonTheme?: Theme | null;
  styleVariant?: string | null;
}> = ({ subscription, universeTag, buttonTheme, styleVariant }) => {
  return (
    <div className="overflow-visible">
      <div className="grid gap-4 py-4">
        <div className="grid grid-flow-col">
          <span className="heading-03">
            {formatPrice({
              currency: subscription.recurringPrice.currency,
              value: subscription.recurringPrice.price,
              type: "monthly",
            })}
          </span>
        </div>

        <Button
          as={Anchor}
          {...getPageLink({
            pageType: "checkout",
            universeTag,
            params: subscription.sku,
          })}
          className={classNames(
            getThemeClassName(styleVariant === "color" ? "primary" : null),
            {
              "!hover:bg-[var(--bg-primary)] !border-none":
                styleVariant === "color",
            },
            "w-fit min-w-[239px] px-8 py-4",
          )}
          style={{
            ...(styleVariant === "color"
              ? getCSSPropertiesTheme(buttonTheme)
              : {}),
          }}
          variant="primary"
        >
          Bli abonnent <PiArrowRight size={24} />
        </Button>
      </div>
    </div>
  );
};
