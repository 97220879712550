import { Radio } from "@cappelendamm/design-system";

const RadioButtonVariant = ({
  title,
  options,
  onSubmit,
}: {
  title: string;
  options: string[];
  onSubmit: () => void;
}) => {
  return (
    <div className="flex flex-col">
      <h3 className="heading-03 mb-6">{title}</h3>
      <div className="mb-6 flex flex-col gap-3">
        {options.map((option) => (
          <label key={option} className="flex gap-2">
            <Radio onClick={onSubmit} name="options" />
            <p>{option}</p>
          </label>
        ))}
      </div>
    </div>
  );
};

export default RadioButtonVariant;
