import { ReviewCardProps } from "./reviewCardType";
import { PortableText } from "@/portable-text/PortableText";
import { truncatePortableText } from "@/portable-text/proseClamper";
import { Dice } from "@/components/Dice";
import { Anchor } from "@/navigation";
import { getPageLink } from "@/navigation/selectors";

export const ReviewCard = ({
  diceValue,
  title,
  content,
  date,
  source,
  slug,
  universeTag,
}: ReviewCardProps) => {
  const formatDate = (date: string) => {
    const dateInternal = new Date(date);
    return dateInternal.toLocaleDateString("no-NO");
  };
  return (
    <>
      <Anchor
        {...getPageLink({
          pageType: "book",
          params: slug.current,
          universeTag,
        })}
        data-testid="review-card-item"
        className="p-section flex w-full flex-col gap-3 rounded-10 border-2 border-gray-100 bg-white"
      >
        <div className="flex flex-row gap-3 overflow-hidden">
          {diceValue && (
            <Dice
              value={diceValue}
              weight="fill"
              className="h-9 w-9 shrink-0"
            />
          )}
          <strong className="self-center">{title}</strong>
        </div>
        <div>
          <PortableText value={truncatePortableText(content, 5)} />
        </div>
        <span className="text-[#494546]">
          {source + ", " + formatDate(date)}
        </span>
      </Anchor>
    </>
  );
};
