"use client";
import { CSSProperties, FC, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import ProductCard from "./ProductCard/ProductCard";
import { TrackingItem, TrackingSection } from "@/tracking/types";
import { getTrackingDataset } from "@/tracking/utils";
import { ViewECommerceItemListEvent } from "@/tracking/ga-properties";
import tracker from "@/tracking/tracker";
import ComponentViewTracker from "@/tracking/CustomTracking/ComponentViewTracker";
import { SectionProps } from "../Section";
import { ReviewCard } from "./ReviewCard/ReviewCard";
import { ReviewCardProps } from "./ReviewCard/reviewCardType";
import { PageTurner } from "./PageTurner/PageTurner";
import { getViewEComItemListEvent } from "@/tracking/CustomTracking/eventFunctions";
import { ContentData } from "@/cms/types";
import { ProductContent } from "@/products";
import { ProductCarouselSectionQueryResult } from "@/cms/sanity.types";

/*
TODO: Reimplement this to be more expandable regardless of type of list
Carousel has to be more agnostic of what type of content it shows
An idea is to separate ProductCarousel back to what it used to be - because it needs extra tracking
and also the need for grid alignment in differnly sized elements in the ProductCard
And then make a generic carousel based on the carousel functionality in the Designsystem
which is completly agnostic to its children.
*/

const getProductCarouselTrackingEvent = ({
  _id,
  internalTitle,
  currentCarouselInteraction,
  iterableCarouselItems,
  fromIdx,
  toIdx,
}: {
  _id?: string;
  internalTitle?: string | null;
  currentCarouselInteraction?: string;
  iterableCarouselItems: ProductContent[] | ReviewCardProps[];
  fromIdx: number;
  toIdx: number;
}) => {
  if (isProductList(iterableCarouselItems)) {
    const visibleItemsListEvent = getViewEComItemListEvent(
      /* @ts-ignore this is product, check is done in if*/
      iterableCarouselItems?.slice(fromIdx, toIdx),
      _id,
      internalTitle,
      currentCarouselInteraction,
    );
    return visibleItemsListEvent;
  } else if (isReviewsList(iterableCarouselItems)) {
  }
};

const isProductList = (
  iterableCarouselItems: ProductContent[] | ReviewCardProps[],
) => !isReviewsList(iterableCarouselItems);

const isReviewsList = (
  iterableCarouselItems: ProductContent[] | ReviewCardProps[],
) => new Set(["review"]).has(iterableCarouselItems[0]?.type);

export type ProductCarouselProps =
  ContentData<ProductCarouselSectionQueryResult> &
    Pick<SectionProps, "index"> & {
      reviews?: ReviewCardProps[];
      products?: ProductContent[];
    };

export const ProductCarousel: FC<
  ProductCarouselProps & JSX.IntrinsicElements["section"]
> = ({
  data: {
    _id,
    title,
    variant,
    theme,
    internalTitle,
    visibleElementsInViewport = 5,
    universeTag,
  },
  products,
  reviews,
  index: sectionIndex,
}) => {
  const iterableCarouselItems =
    products && products.length > 0
      ? products
      : reviews && reviews.length > 0
        ? reviews
        : [];

  // Litt usikker på om sectionTracking trengs med all den andre trackingen som er på plass?
  // Er det grunn til å differensiere mellom vanlig sectionTracking og scroll tracking?
  // Mye mulig det gir mening, hvis man tenker i 2 forskjellige baner og vil finne igjen de samme variablene
  // En gang leter man etter ecommerce events en annen section_view osv
  const sectionTracking = getTrackingDataset<
    TrackingSection & Partial<TrackingItem>
  >({
    sectionId: _id ?? "section-id-unknown",
    sectionName: internalTitle?.replaceAll(" ", "-") ?? "carousel_section_name",
    sectionType: "carousel",
    sectionIndex: sectionIndex ?? 0,
  });
  ////////////////////////////////////////////////////////////////
  visibleElementsInViewport = visibleElementsInViewport ?? 5;

  const [currPage, setCurrPage] = useState(1);
  const pages = Math.ceil(
    iterableCarouselItems
      ? iterableCarouselItems?.length / visibleElementsInViewport
      : 0,
  );

  const carouselId = "ul_" + sectionIndex;

  // For trackCarouselInteractions() /////////////
  const scrollDirection = useRef("");
  const timeoutRef = useRef<NodeJS.Timeout>();
  const timeoutCounter = useRef(0);
  const prevVisibleItemsSliceToIdx = useRef(0);
  ////////////////////////////////////////////////
  const trackCarouselInteractions = (visibleItems: {
    fromIdx: number;
    toIdx: number;
  }) => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      timeoutCounter.current += 1;

      // Prepare scroll direction variable
      if (scrollDirection.current === "") {
        if (visibleItems.toIdx < prevVisibleItemsSliceToIdx.current) {
          scrollDirection.current = "left_scroll";
        } else if (visibleItems.toIdx > prevVisibleItemsSliceToIdx.current) {
          scrollDirection.current = "right_scroll";
        } else {
          scrollDirection.current = "scrolled_forth_and_back";
        }
      }
      prevVisibleItemsSliceToIdx.current = visibleItems.toIdx;

      const trackingEvent = getProductCarouselTrackingEvent({
        _id,
        internalTitle,
        currentCarouselInteraction: scrollDirection.current,
        iterableCarouselItems,
        fromIdx: visibleItems.fromIdx,
        toIdx: visibleItems.toIdx,
      });
      trackingEvent && tracker.trackEvent(trackingEvent);

      scrollDirection.current = "";
    }, 250);
  };

  const onNext = () => {
    var child = document.querySelector("#" + carouselId);
    child?.scrollTo(child.scrollLeft + ((child as any).offsetWidth + gap), 0);
    scrollDirection.current = "next_button_clicked";
  };
  const onPrevious = () => {
    var child = document.querySelector("#" + carouselId);
    child?.scrollTo(child.scrollLeft - ((child as any).offsetWidth + gap), 0);
    scrollDirection.current = "previous_button_clicked";
  };

  const onScroll = () => {
    var child = document.querySelector<HTMLElement>("#" + carouselId);
    const pageDecimal =
      ((child as HTMLElement)?.scrollLeft +
        (child as HTMLElement)?.offsetWidth -
        gap * currPage) /
      ((child as HTMLElement)?.offsetWidth + gap);
    const page = Math.ceil(pageDecimal);

    const elementWidth = iterableCarouselItems
      ? (child as HTMLElement)?.scrollWidth / iterableCarouselItems?.length
      : 0;
    const visibleItemsSliceIdxs = {
      fromIdx: Math.round((child as HTMLElement)?.scrollLeft / elementWidth),
      toIdx: Math.round(
        ((child as HTMLElement)?.scrollLeft +
          (child as HTMLElement)?.offsetWidth) /
          elementWidth,
      ),
    };

    trackCarouselInteractions(visibleItemsSliceIdxs);
    page !== currPage && setCurrPage(page);
  };

  const gap =
    visibleElementsInViewport === 5
      ? 48
      : visibleElementsInViewport === 6
        ? 24
        : 24;

  // Executes only first render cycle
  // useEffect didn't work here, component not yet mounted, so componentQuerySelector didnt work
  const initialEventRef = useRef(0);
  if (initialEventRef.current < 2) {
    initialEventRef.current += 1;
  }

  const viewItemListEvent =
    getProductCarouselTrackingEvent({
      _id,
      internalTitle,
      currentCarouselInteraction: scrollDirection.current,
      iterableCarouselItems,
      fromIdx: 0,
      toIdx: visibleElementsInViewport,
    }) ?? ({} as ViewECommerceItemListEvent);

  const componentQuerySelector =
    initialEventRef.current === 1
      ? `[id='product-carousel-section-${_id}']`
      : "";

  // END Executes only first render cycle

  const children = useMemo(() => {
    if (isProductList(iterableCarouselItems))
      return products?.map((item: any, index: number) => (
        <ProductCard
          key={index}
          item={item}
          index={index}
          universeTag={universeTag}
          cssContents={true}
        />
      ));
    else if (isReviewsList(iterableCarouselItems))
      return reviews?.map((review: any, idx: number) => (
        <ReviewCard key={idx} {...review} universeTag={universeTag} />
      ));
  }, [products, reviews]);

  if (!iterableCarouselItems || !(iterableCarouselItems?.length > 0))
    return null;

  return (
    <>
      {initialEventRef.current === 1 && (
        <ComponentViewTracker
          query={componentQuerySelector}
          event={viewItemListEvent}
        />
      )}
      <section
        aria-labelledby={_id}
        id={"product-carousel-section-" + _id}
        {...sectionTracking}
        className={classNames(
          "py-section mx-body lg:w-body hidden-scrollbar",
          "relative bg-[var(--background-color)] text-[var(--color)] sm:rounded-lg",
        )}
        style={
          {
            "--background-color": variant ? theme?.background?.hex : "none",
            "--color": variant ? theme?.text?.hex : "none",
          } as CSSProperties
        }
      >
        <div
          className={classNames("app-grid py-[var(--section-py)] pr-0 sm:p-0", {
            "sm:p-section": variant === "color",
          })}
        >
          <h2
            className="heading-02 col-span-6 mb-4 mr-0 sm:col-span-4 sm:mr-6 md:inline-flex"
            id={_id}
          >
            {title}
          </h2>
          <div
            className={classNames(
              "absolute hidden gap-4 md:inline-flex",
              {
                "right-6 top-12": variant === "color",
              },
              {
                "right-0 top-6": variant === "none",
              },
              {
                hidden:
                  iterableCarouselItems?.length >= visibleElementsInViewport,
              },
            )}
          >
            {iterableCarouselItems &&
              iterableCarouselItems.length / visibleElementsInViewport > 1 && (
                <>
                  <PageTurner
                    currPage={currPage}
                    pages={pages}
                    onPrevious={onPrevious}
                    onNext={onNext}
                  />
                </>
              )}
          </div>
          <ul
            className={classNames(
              "hidden-scrollbar col-span-full grid w-full snap-x grid-flow-col overflow-x-auto scroll-smooth will-change-scroll",
              {
                "grid-rows-[auto_auto_auto_auto]": isProductList(
                  iterableCarouselItems,
                ),
              },
              {
                "grid-rows-[auto]": isReviewsList(iterableCarouselItems),
              },
              {
                "auto-cols-[calc((100%/1.5)-16px)] gap-x-4":
                  visibleElementsInViewport === 5 ||
                  visibleElementsInViewport === 6,
              },
              {
                "xs:auto-cols-[calc((100%/2.5)-16px)] xs:gap-x-4":
                  visibleElementsInViewport === 5 ||
                  visibleElementsInViewport === 6,
              },
              {
                "sm:auto-cols-[calc((100%/4.5)-32px)] sm:gap-x-8":
                  visibleElementsInViewport === 5 ||
                  visibleElementsInViewport === 6,
              },
              {
                "md:auto-cols-[calc((100%/5)-48px+(48px/5))] md:gap-x-12":
                  visibleElementsInViewport === 5,
              },
              {
                "md:auto-cols-[calc((100%/6)-24px+(24px/6))] md:gap-x-6":
                  visibleElementsInViewport === 6,
              },
            )}
            id={carouselId}
            onScroll={() => onScroll()}
          >
            {children}
          </ul>
        </div>
      </section>
    </>
  );
};
export default ProductCarousel;
