import { CaretLeft, CaretRight } from "@phosphor-icons/react/dist/ssr";
import classNames from "classnames";

type PageTurnerProps = {
  currPage: number;
  pages: number;
  onPrevious: () => void;
  onNext: () => void;
};

export const PageTurner = ({
  currPage,
  pages,
  onPrevious,
  onNext,
}: PageTurnerProps) => {
  return (
    <>
      <button
        className={classNames({
          "opacity-30": currPage === 1,
        })}
        onClick={() => onPrevious()}
      >
        <CaretLeft size={25} />
      </button>
      <span className="w-5 text-center">
        {currPage}/{pages}
      </span>
      <button
        data-testid="carousel-section-next-page"
        className={classNames({
          "opacity-30": currPage === pages,
        })}
        onClick={() => onNext()}
      >
        <CaretRight size={25} />
      </button>
    </>
  );
};
